import './App.css';
import Index from './Pages/Index';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route  path="/" exact element={<Index/>}/>
    </Routes>
  </BrowserRouter>

  );
}

export default App;
