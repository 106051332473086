import React, { useEffect,useState } from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom';
import Web3 from 'web3';


function Index() {

    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sponsorCode = urlParams.get('sponsor');
        if(sponsorCode != null){
            sessionStorage.setItem('SponsorID', sponsorCode);
        };
       alert(sessionStorage.getItem('SponsorID'));
    })

    const walletConnect = async () =>{

        if (window.ethereum) {
          try {
            // Request account access if needed
            await window.ethereum.enable();
      
            // Create a new web3 instance with the provider
            const web3Instance = new Web3(window.ethereum);
      
            // Set the web3 instance and user's account address
            setWeb3(web3Instance);
            const accounts = await web3Instance.eth.getAccounts();
            getMyAccount(accounts[0]);
            console.log("Web 3 :", web3, "Account :",accounts)
      
          } catch (error) {
            console.error('Error connecting to the wallet:', error);
          }
        } else {
          console.error('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
      
      }

      const getMyAccount = (myAccount) => {

        setAccount(myAccount)
       
       };

    return (
        <>
            {/* <Header /> */}
            {/* Header Area Start  */}
            <header className="header">
                <div className="overlay" />
                <div className="mainmenu-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <Link className="navbar-brand" href="/">
                                        <img src="assets/images/logo.png" alt="" />
                                    </Link>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu" aria-controls="main_menu" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon" />
                                    </button>
                                    <div className="collapse navbar-collapse fixed-height" id="main_menu">
                                        <ul className="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <Link className="nav-link active" role="button" aria-haspopup="true" aria-expanded="false">
                                                    Staking

                                                </Link>

                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" href="javascript:void(0)">Affilliate Marketing</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" href="javascript:void(0)">TMC</Link>
                                            </li>

                                        </ul>
                                        <Link href="javascript:void(0)" className="mybtn1" onClick={walletConnect} > Connect Wallet</Link>
                                        <Link href="javascript:void(0)" className="mybtn2 ml-2">USDT </Link>


                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Main-Menu Area Start*/}
            </header>
            {/* Header Area End  */}
            {/* Hero Area Start */}
            <div className="hero-area hero-area2 hero-area3">
                <img className="shape parallax5" src="assets/images/home/h2-shape.png" alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content">
                                <div className="content">
                                    <h5 className="subtitle">
                                        Welcome To
                                    </h5>
                                    <h1 className="subtitle mt-4">
                                        {/* <img src="assets/images/text.png" alt=""> */}
                                        TRUE-MAX Staking
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Hero Area End */}
            {/* Counter Area Start */}
            <section className="counter-section">
                <div className="container mt-10">
                    <div className="text-center">
                        <h3>Staking Details</h3>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>Total Staking Amount</h5>
                                </div>
                                <div>
                                    <h6 className="text-warning"> TMC</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>Total ROI</h5>
                                </div>
                                <div>
                                    <h6 className="text-warning"> TMC</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>Available Amount</h5>
                                </div>
                                <div>
                                    <h6 className="text-warning"> TMC</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>2x Reward </h5>
                                </div>
                                <div>
                                    <h6 className="text-warning"> TMC</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>Receive Reward</h5>
                                </div>
                                <div>
                                    <h6 className="text-warning">TMC</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 mt-3">
                            <div className="single-play text-left ">
                                <div>
                                    <h5>Reamin Reward</h5>
                                </div>
                                <div>
                                    <h6 className="text-warning"> TMC</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 mt-3">
                            <div className="subscribe-area">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="subscribe-box">
                                                <img className="left" src="assets/images/vr.png" alt="" />
                                                <img className="right" src="assets/images/game%20controler_.png" alt="" />
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className="heading-area">
                                                            <h5 className="sub-title">
                                                                Withdraw Amount
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <form action="#" className="form-area">
                                                            <input type="text" placeholder="Enter TMC Amount" />
                                                            <button className="mybtn1" type="submit">Withdraw
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Counter Area End */}
            {/* Game play area start */}
            <section className="game-play-section">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h4 className="sub-title ">
                                    Stake TMC
                                </h4>
                                <h6 className="text">
                                    Please enter the TMC you want to stake Any Fees applied at unstake and withdraw stage will be based on the date you last staked.
                                </h6>
                            </div>
                            <div className="row">
                                <div className="l-arcive-box ccol-lg-12 col-md-12 col-12 table-bordered ">
                                    <h4 className="text-white"> Balance <b className="text-warning">0.00000 </b>TMC</h4>
                                    <form>
                                        <input type="text" className="form-control background-white text-dark mt-3" id="staketmc" name="staketmc" required placeholder="Enter Amount" />
                                        <input type="submit" value="STAKE" className="mybtn1 text-dark mt-2" defaultValue="Stake" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Game play area end */}
            {/* Latest arcive area start */}
            <section className="latest-arcive">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h4 className="sub-title ">
                                    REFERRAL MARKETING
                                </h4>
                            </div>
                            <div className="row">
                                <div className=" col-lg-6 col-md-6 col-12  ">
                                    <div className="container">
                                        <div className="l-arcive-box table-bordered mt-3">
                                            <h4 className="text-white"> Wallet Connect</h4>
                                             <h4 className="text-warning">{account}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-lg-6 col-md-6 col-12  ">
                                    <div className="container">
                                        <div className="l-arcive-box table-bordered mt-3">
                                            <h4 className="text-white"> Referred By</h4>
                                            <h4 className="text-warning"></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-lg-12 col-md-12 col-12  ">
                                    <div className="container">
                                        <div className="l-arcive-box table-bordered mt-3">
                                            <h4 className="text-white">Referral Link</h4>
                                            <h4 className="text-warning">000000000000000000000000</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Latest arcive area End */}
            {/* join us area start */}
            <section className="join_us">
                <img className="l-image" src="assets/images/joinus_left_img.png" alt="" />
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-xl-8">
                            <div className="section-heading content-center">
                                <h5 className="subtitle">
                                    Portfolio
                                </h5>
                                <div className="row">
                                    <div className=" col-lg-6 col-md-6 col-12  ">
                                        <div className="container">
                                            <div className="l-arcive-box table-bordered mt-3">
                                                <h4 className="text-white">Direct Team</h4>
                                                <h4 className="text-warning"></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-12  ">
                                        <div className="container">
                                            <div className="l-arcive-box table-bordered mt-3">
                                                <h4 className="text-white"> Total Team</h4>
                                                <h4 className="text-warning"></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-12  ">
                                        <div className="container">
                                            <div className="l-arcive-box table-bordered mt-3">
                                                <h4 className="text-white">Total Team Business</h4>
                                                <h4 className="text-warning"></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-12  ">
                                        <div className="container">
                                            <div className="l-arcive-box table-bordered mt-3">
                                                <h4 className="text-white"> Total Level Income</h4>
                                                <h4 className="text-warning"></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* join us area  end */}
            {/* How play area start */}
            <section className="how-to-play">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h5 className="subtitle">
                                    Withdraw Level Income
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-10">
                            <div className>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="subscribe-box">
                                                <img className="left" src="assets/images/vr.png" alt="" />
                                                <img className="right" src="assets/images/game%20controler_.png" alt="" />
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className="heading-area">
                                                            <h5 className="sub-title">
                                                                Withdraw Amount
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <form action="#" className="form-area">
                                                            <input type="text" placeholder="Enter TMC Amount" />
                                                            <button className="mybtn1" type="submit">Withdraw
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </section>
            {/* How play area  end */}
            {/* Table Area Start */}
            <section className="user-main-dashboard counter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <main>
                                <div className="main-box">
                                    <div className="header-area">
                                        <h4 className="text-center">Level Bonus</h4>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-borderless">
                                            <thead className="text-warning text-center">
                                                <th>Level</th>
                                                <th>Team</th>
                                                <th>Business</th>
                                                <th>Percentage</th>
                                                <th>Bonus</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g1.png" alt="" />
                                                            <div className="content">
                                                                <h4>1</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g2.png" alt="" />
                                                            <div className="content">
                                                                <h4>2</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g3.png" alt="" />
                                                            <div className="content">
                                                                <h4>3</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g4.png" alt="" />
                                                            <div className="content">
                                                                <h4>4</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g5.png" alt="" />
                                                            <div className="content">
                                                                <h4>5</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>6</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>7</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>8</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>9</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>10</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>11</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>12</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>13</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>14</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>15</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>16</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>17</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>18</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>19</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="game-info">
                                                            <img src="assets/images/gamer/g6.png" alt="" />
                                                            <div className="content">
                                                                <h4>20</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="players">
                                                            <ul>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm1.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm2.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm3.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link href="#">
                                                                        <img src="assets/images/player/sm4.png" alt="" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        0+
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">

                                                            <h4>0</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="ratio">
                                                            <h4>0%</h4>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 class="text-waring">0.0 TMC</h5>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </section>
            {/* Table Area End */}
            <Footer />
            {/* Back to Top Start */}
            <div className="bottomtotop">
                <i className="fas fa-chevron-right" />
            </div>
            {/* Back to Top End */}
            {/* User Message Modal Start*/}
            <div className="modal fade" id="usermessage" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </div>
                        <div className="modal-body">
                            <div id="mycontainer">
                                <aside>
                                    <header>
                                        <input type="text" placeholder="search" />
                                    </header>
                                    <ul>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status orange" />
                                                    offline
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_02.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_03.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status orange" />
                                                    offline
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_05.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status orange" />
                                                    offline
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_06.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_07.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_08.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_09.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status green" />
                                                    online
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_10.jpg" alt="" />
                                            <div>
                                                <h2>Prénom Nom</h2>
                                                <h3>
                                                    <span className="status orange" />
                                                    offline
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </aside>
                                <main>
                                    <header>
                                        <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt="" />
                                        <div>
                                            <h2>Vincent Porter</h2>
                                            <h3>already 1902 messages</h3>
                                        </div>
                                    </header>
                                    <ul id="chat">
                                        <li className="you">
                                            <div className="entete">
                                                <span className="status green" />
                                                <h2>Vincent</h2>
                                                <h3>10:12AM, Today</h3>
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                            </div>
                                        </li>
                                        <li className="me">
                                            <div className="entete">
                                                <h3>10:12AM, Today</h3>
                                                <h2>Vincent</h2>
                                                <span className="status blue" />
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                            </div>
                                        </li>
                                        <li className="me">
                                            <div className="entete">
                                                <h3>10:12AM, Today</h3>
                                                <h2>Vincent</h2>
                                                <span className="status blue" />
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                OK
                                            </div>
                                        </li>
                                        <li className="you">
                                            <div className="entete">
                                                <span className="status green" />
                                                <h2>Vincent</h2>
                                                <h3>10:12AM, Today</h3>
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                            </div>
                                        </li>
                                        <li className="me">
                                            <div className="entete">
                                                <h3>10:12AM, Today</h3>
                                                <h2>Vincent</h2>
                                                <span className="status blue" />
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                                            </div>
                                        </li>
                                        <li className="me">
                                            <div className="entete">
                                                <h3>10:12AM, Today</h3>
                                                <h2>Vincent</h2>
                                                <span className="status blue" />
                                            </div>
                                            <div className="triangle" />
                                            <div className="message">
                                                OK
                                            </div>
                                        </li>
                                    </ul>
                                    <footer>
                                        <textarea placeholder="Type your message" defaultValue={""} />
                                        <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_picture.png" alt="" />
                                        <img src="../../../s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_file.png" alt="" />
                                        <Link href="#">Send</Link>
                                    </footer>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* User Message Modal End*/}
            {/* User Notification Modal Start*/}
            <div className="modal fade" id="usernotification" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4>Notification</h4>
                            <div className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="single-notification">
                                <div className="img">
                                    <img src="assets/images/n1.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="top-area">
                                        <h4>Update Announcement</h4>
                                        <p>2021-03-07  -  23:50:21 </p>
                                    </div>
                                    <div className="middle-area">
                                        <h6>Dear player:</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum dictum congue. Duis fringilla malesuada lobortis. In ultricies venenatis magna ut mollis. Nam sit amet arcu lobortis, porta nisl non, egestas felis. Nulla et finibus massa. Ut varius tristique elit et gravida.</p>
                                    </div>
                                    <div className="bottom-area">
                                        <p>Jugaro.Game Team</p>
                                        <span>April 30 2021</span>
                                    </div>
                                </div>
                            </div>
                            <div className="single-notification">
                                <div className="img">
                                    <img src="assets/images/n1.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="top-area">
                                        <h4>Update Announcement</h4>
                                        <p>2021-03-07  -  23:50:21 </p>
                                    </div>
                                    <div className="middle-area">
                                        <h6>Dear player:</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum dictum congue. Duis fringilla malesuada lobortis. In ultricies venenatis magna ut mollis. Nam sit amet arcu lobortis, porta nisl non, egestas felis. Nulla et finibus massa. Ut varius tristique elit et gravida.</p>
                                    </div>
                                    <div className="bottom-area">
                                        <p>Jugaro.Game Team</p>
                                        <span>April 30 2021</span>
                                    </div>
                                </div>
                            </div>
                            <div className="single-notification">
                                <div className="img">
                                    <img src="assets/images/n1.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="top-area">
                                        <h4>Update Announcement</h4>
                                        <p>2021-03-07  -  23:50:21 </p>
                                    </div>
                                    <div className="middle-area">
                                        <h6>Dear player:</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum dictum congue. Duis fringilla malesuada lobortis. In ultricies venenatis magna ut mollis. Nam sit amet arcu lobortis, porta nisl non, egestas felis. Nulla et finibus massa. Ut varius tristique elit et gravida.</p>
                                    </div>
                                    <div className="bottom-area">
                                        <p>Jugaro.Game Team</p>
                                        <span>April 30 2021</span>
                                    </div>
                                </div>
                            </div>
                            <div className="single-notification">
                                <div className="img">
                                    <img src="assets/images/n1.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="top-area">
                                        <h4>Update Announcement</h4>
                                        <p>2021-03-07  -  23:50:21 </p>
                                    </div>
                                    <div className="middle-area">
                                        <h6>Dear player:</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum dictum congue. Duis fringilla malesuada lobortis. In ultricies venenatis magna ut mollis. Nam sit amet arcu lobortis, porta nisl non, egestas felis. Nulla et finibus massa. Ut varius tristique elit et gravida.</p>
                                    </div>
                                    <div className="bottom-area">
                                        <p>Jugaro.Game Team</p>
                                        <span>April 30 2021</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* User Notification Modal End*/}
            {/* SignIn Area Start */}
            <div className="modal fade login-modal sign-in" id="signin" tabIndex={-1} role="dialog" aria-labelledby="signin" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <div className="modal-body">
                            <ul className="nav l-nav" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <Link className="nav-link mybtn2 active" id="pills-m_login-tab" data-toggle="pill" href="#pills-m_login" role="tab" aria-controls="pills-m_login" aria-selected="true">Login</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link className="nav-link mybtn2" id="pills-m_register-tab" data-toggle="pill" href="#pills-m_register" role="tab" aria-controls="pills-m_register" aria-selected="false">Register</Link>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-m_login" role="tabpanel" aria-labelledby="pills-m_login-tab">
                                    <div className="header-area">
                                        <h4 className="title">Welcome to
                                            JuGARO</h4>
                                    </div>
                                    <div className="form-area">
                                        <form action="#" method="POST">
                                            <div className="form-group">
                                                <input type="text" className="input-field" id="input-name" placeholder="Username" />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="input-field" id="input-email" placeholder="Password" />
                                            </div>
                                            <div className="form-group">
                                                <span>Forgot your password? <Link href="#">recover password</Link></span>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="mybtn2">Login</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-m_register" role="tabpanel" aria-labelledby="pills-m_register-tab">
                                    <div className="header-area">
                                        <span className="bunnus_btn">
                                            <span>Current Contest Pool</span>
                                            <h4>$500</h4>
                                        </span>
                                        <h4 className="title">Play +100 games
                                            and win cash!</h4>
                                        <p className="text">
                                            Fill this outyour majesty &amp; Get Your Bonus
                                        </p>
                                    </div>
                                    <div className="form-area">
                                        <form action="#" method="POST">
                                            <div className="form-group">
                                                <input type="text" className="input-field" id="input-name" placeholder="Username" />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="input-field" id="input-email" placeholder="Enter your Email" />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="input-field" id="input-password" placeholder="Enter your password" />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="input-field" id="input-con-password" placeholder="Enter your Confirm Password" />
                                            </div>
                                            <div className="form-group">
                                                <div className="check-group">
                                                    <input type="checkbox" className="check-box-field" id="input-terms" defaultChecked />
                                                    <label htmlFor="input-terms">
                                                        I agree with <Link href="#">terms and Conditions</Link> and  <Link href="#">privacy policy</Link>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="mybtn2">Register</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SignIn Area End */}

        </>
    )
}

export default Index