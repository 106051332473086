import React, { Fragment } from 'react'

function Footer() {
  return (
    <Fragment>
         {/* Footer Area Start */}
      <footer className="footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="flogo">
                <a href="#"><img src="assets/images/footer-og.png" alt="" /></a>
              </div>
              <div className="social-links">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="#">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>Copyright © 2021.All Rights Reserved By True Max</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
    </Fragment>
  )
}

export default Footer